import axios from "axios";
const API_BOT = 'https://bot.centrocolor.co/api';
const DOMAIN = 'app.centrocolor.co';
const AXIOSHEADERS = {
  headers: {
    "content-type": "application/json",
  },
}
// eslint-disable-next-line
let PAYLOAD = {
  domain: `${DOMAIN}`,
  status: "DATA NOT FOUND",
  request: 'DATA NOT FOUND',
}

export const analytic = {

  // ===============================================================================
  async successRecord(data:any ) {
    const ENDPOINT = `${API_BOT}/notificator`
    PAYLOAD.status = "🟢 Success Record"
    PAYLOAD.request = data 
    return await axios.post(ENDPOINT,PAYLOAD,AXIOSHEADERS);
  },
  // ===============================================================================
  async failRecord(data: any) {
    const ENDPOINT = `${API_BOT}/notificator`
    PAYLOAD.status = "🔴 Fail Record"
    PAYLOAD.request = data
    return await axios.post(ENDPOINT,PAYLOAD,AXIOSHEADERS);
  },
  // ===============================================================================
  // ===============================================================================
  async successRecordWithWarnColor(data:any) {
    const ENDPOINT = `${API_BOT}/notificator`
    PAYLOAD.status = "🟠 Success Record"
    PAYLOAD.request = data 
    return await axios.post(ENDPOINT,PAYLOAD,AXIOSHEADERS);
  },
}