import axios from "axios";
import { analytic } from "./analytic";
import { util } from "./util";
const API2 = 'https://pocketdb.centrocolor.co/api';
const API = 'https://pocketdb2.centrocolor.co/api';
const TOKEN = sessionStorage.getItem("cc-token");
const AXIOSHEADERS = {
  headers: {
    "content-type": "application/json",
    Authorization: `User ${TOKEN}`,
  },
}
interface abono {
  fecha: string;
  valor: number;
  medio: string;
}
export const api = {
  async getCuentas() {
    const endPoint = `${API}/collections/cuentas/records?Page=1`
    try {
      const response = await axios.get(endPoint,
        AXIOSHEADERS
      );
      return response.data.items[0];
    } catch (e: any) {
      if (e.response.data.message) {
        await analytic.failRecord(e.response.data)
      } else {
        await analytic.failRecord(e)
      }
    }
  },
  async updateCuentas(data: any, cuentaId: string) {
    const endPoint = `${API}/collections/cuentas/records/${cuentaId}`

    try {
      const response = await axios.patch(endPoint,
        data,
        AXIOSHEADERS
      );
      return response
    } catch (e: any) {
      if (e.response.data.message) {
        await analytic.failRecord(e.response.data)
      } else {
        await analytic.failRecord(e)
      }

    }
  },
  async injectCuentasValue(valor: number, medioPago: string) {
    medioPago = medioPago.toLowerCase()
    const cuentas = await this.getCuentas()

    const info: any = {};
    // eslint-disable-next-line
    let analyticData = {
      EVENTO: 'ABONO',
      MEDIO: '',
      MONTO_PREVIO: '',
      MONTO_AGREGADO: '',
      MONTO_NUEVO: '',

    }
    let suma = 0
    analyticData.MONTO_AGREGADO = util.prettyNumber(valor)
    analyticData.MONTO_PREVIO = util.prettyNumber(cuentas[`${medioPago}`])
    analyticData.MEDIO = `${medioPago.toUpperCase()}`
    suma = cuentas[`${medioPago}`] + valor;
    
    info[`${medioPago}`] = suma;
    const res = await this.updateCuentas(info, cuentas.id);
    analyticData.MONTO_NUEVO = util.prettyNumber(res?.data[`${medioPago}`])
    if (res?.status == 200) {
      analytic.successRecord(analyticData)
    } else {
      analytic.failRecord(res)
    }
    return res


  },
  async injectCuentasValueFromAbonosList(abonosList: abono[]) {
    const cuentas = await this.getCuentas()
    for (const i of abonosList) {
      console.log('abonoque llega a Inject', i)
      cuentas[`${i.medio.toLowerCase()}`] = cuentas[`${i.medio.toLowerCase()}`] + i.valor
    }
    return await this.updateCuentas(cuentas, cuentas.id);

  },
  async sustractCuentasValue(valor: number, medioPago: string) {
    medioPago = medioPago.toLowerCase()
    // eslint-disable-next-line
    let analyticData = {
      EVENTO: 'EGRESO',
      MEDIO: '',
      MONTO_PREVIO: '',
      MONTO_RESTADO: '',
      MONTO_NUEVO: '',

    }
    const cuentas = await this.getCuentas()
    const info: any = {};
    let resta = 0
    analyticData.MONTO_RESTADO = util.prettyNumber(valor)
    analyticData.MONTO_PREVIO = util.prettyNumber(cuentas[`${medioPago}`])
    analyticData.MEDIO = `${medioPago.toUpperCase()}`
    resta = cuentas[`${medioPago}`] - valor;
    info[`${medioPago}`] = resta;
    
    const res = await this.updateCuentas(info, cuentas.id);
    analyticData.MONTO_NUEVO = util.prettyNumber(res?.data[`${medioPago}`])
    if (res?.status == 200) {
      analytic.successRecordWithWarnColor(analyticData)
    } else {
      analytic.failRecord(res)
    }
    return res


  },
  async sustractCuentasValueFromAbonosList(abonosList: abono[]) {
    const cuentas = await this.getCuentas()
    for (const i of abonosList) {
      cuentas[`${i.medio.toLowerCase()}`] = cuentas[`${i.medio.toLowerCase()}`] - i.valor
    }
    await this.updateCuentas(cuentas, cuentas.id);

  },
  // ===============================================================================
  async getAllOrders() {

    const endPoint = `${API}/collections/ordenes/records?page=1&perPage=100`
    const firstCall = await axios.get(
      endPoint,
      AXIOSHEADERS
    );
    // eslint-disable-next-line
    let datasArray: any = []
    // eslint-disable-next-line
    let finalData: any = { items: [], page: 0, totalPages: 0 }
    for (let i = 1; i <= firstCall.data.totalPages; i++) {
      const tempPoint = `${API}/collections/ordenes/records?page=${i}&perPage=100`
      const call = await axios.get(
        tempPoint,
        AXIOSHEADERS
      );
      datasArray.push(call.data)

    }
    for (const iterator of datasArray) {
      for (const k of iterator.items) {
        finalData.items.push(k)
      }

      if (iterator.page > finalData.page) finalData.page = iterator.page
      if (iterator.totalPages > finalData.totalPages) finalData.totalPages = iterator.totalPages
    }

    return { data: finalData }
  },
  async getAllClients() {

    const endPoint = `${API2}/collections/clientes/records?page=1&perPage=100`
    const firstCall = await axios.get(
      endPoint,
      AXIOSHEADERS
    );
    // eslint-disable-next-line
    let datasArray: any = []
    // eslint-disable-next-line
    let finalData: any = { items: [], page: 0, totalPages: 0 }
    for (let i = 1; i <= firstCall.data.totalPages; i++) {
      const tempPoint = `${API2}/collections/clientes/records?page=${i}&perPage=100`
      const call = await axios.get(
        tempPoint,
        AXIOSHEADERS
      );
      datasArray.push(call.data)

    }
    for (const iterator of datasArray) {
      for (const k of iterator.items) {
        finalData.items.push(k)
      }

      if (iterator.page > finalData.page) finalData.page = iterator.page
      if (iterator.totalPages > finalData.totalPages) finalData.totalPages = iterator.totalPages
    }
    
    return { data: finalData }
  },
  async getAllEgresos() {

    const endPoint = `${API}/collections/egresos/records?page=1&perPage=100`
    const firstCall = await axios.get(
      endPoint,
      AXIOSHEADERS
    );
    // eslint-disable-next-line
    let datasArray: any = []
    // eslint-disable-next-line
    let finalData: any = { items: [], page: 0, totalPages: 0 }
    for (let i = 1; i <= firstCall.data.totalPages; i++) {
      const tempPoint = `${API}/collections/egresos/records?page=${i}&perPage=100`
      const call = await axios.get(
        tempPoint,
        AXIOSHEADERS
      );
      datasArray.push(call.data)

    }
    for (const iterator of datasArray) {
      for (const k of iterator.items) {
        finalData.items.push(k)
      }

      if (iterator.page > finalData.page) finalData.page = iterator.page
      if (iterator.totalPages > finalData.totalPages) finalData.totalPages = iterator.totalPages
    }

    return { data: finalData }
  },

  async updateOrder(data: any, ordenId: string) {
    const endPoint = `${API}/collections/ordenes/records/${ordenId}`

    try {
      await axios.patch(
        endPoint,
        data,
        AXIOSHEADERS
      );
    } catch (e: any) {
      if (e.response.data.message) {
        await analytic.failRecord(e.response.data)
      } else {
        await analytic.failRecord(e)
      }

    }
  },
  async deleteOrderItem(id: string) {
    const endPoint = `${API}/collections/ordenes_items/records/${id}`

    try {
      return await axios.delete(
        endPoint,
        AXIOSHEADERS
      );
    } catch (e: any) {
     console.log(`fail delete: ${id}`,e)

    }
  },
  async getOrderItems(id: string) {
    const endPoint = `${API}/collections/ordenes_items/records/${id}`

    try {
      return await axios.get(
        endPoint,
        AXIOSHEADERS
      );
    } catch (e: any) {
      if (e.response.data.message) {
        await analytic.failRecord(e.response.data)
      } else {
        await analytic.failRecord(e)
      }

    }
  },
  async createOrderItems(data: any) {
    const endPoint = `${API}/collections/ordenes_items/records`

    try {
      return await axios.post(
        endPoint,
        data,
        AXIOSHEADERS
      );
    } catch (e: any) {
      if (e.response.data.message) {
        await analytic.failRecord(e.response.data)
      } else {
        await analytic.failRecord(e)
      }

    }
  },
  async createOrder(data: any) {
    const endPoint = `${API}/collections/ordenes/records`

    try {
      const response = await axios.post(
        endPoint,
        data,
        AXIOSHEADERS
      );
       analytic.successRecord(`Se creo la orden ${data.n_orden} por ${util.prettyNumber(data.monto)}`)
        return response
    } catch (e: any) {
      if (e.response.data.message) {
        await analytic.failRecord(e.response.data)
      } else {
        await analytic.failRecord(e)
      }

    }
   
  },
  async createUser(data: any) {
    const endPoint = `${API2}/collections/clientes/records`

    try {
      const response = await axios.post(
        endPoint,
        data,
        AXIOSHEADERS
      );
      analytic.successRecord({"NuevoUsuario":data})
      return response
    } catch (e: any) {
      if (e.response.data.message) {
        await analytic.failRecord(e.response.data)
      } else {
        await analytic.failRecord(e)
      }

    }
  },
}