
import DecimalFormat from "decimal-format";

const decimalFormat = new DecimalFormat("$ #,###.###");
export const util = {

  // ===============================================================================
   prettyNumber(data:any ) {
   return decimalFormat.format(data);
  },
  // ===============================================================================
 
}